import { Spin, Tabs, message } from "antd";
import CommonDivWidget from "components/CommonDivWidget/CommonDivWidget";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import "./HomeSettingPage.css";
import {
  apiGetActivityList,
  apiGetRegistrationSettings,
} from "services/dashboardService";
import GeneralTab from "./GeneralTab";
import HomeTab from "./HomeTab";
import RegistrationTab from "./RegistrationTab";
import ExtraTab from "./ExtraTab";
import WaiverTab from "./WaiverTab";
function HomeSettingPage(props) {
  const { TabPane } = Tabs;
  const { id } = useParams();
  const userDataStore = useSelector((x) => x.userDataStore);

  const [activityList, setActivityList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [registrationID, setRegistrationID] = useState("");
  const [currentBackgroundUrl, setCurrentBackgroundUrl] = useState("");
  const [currentLogoUrl, setCurrentLogoUrl] = useState("");
  const [currentInfoUrl, setCurrentInfoUrl] = useState("");
  const [isBusy, setIsBusy] = useState(false);
  const [settingInfo, setSettingInfo] = useState(undefined);

  useEffect(() => {
    getActivityList();
    if (id != undefined) {
      setRegistrationID(id);
      getSettingInfo(id);
    }
  }, [id]);

  const getActivityList = async () => {
    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    apiGetActivityList()
      .then((res) => {
        console.log("res:", res)
        if (res != undefined) {
          setActivityList(res);
        } else {
          setActivityList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getSettingInfo = async (regID) => {
    setIsBusy(true);
    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    params.append("registration_id", regID);
    apiGetRegistrationSettings(params)
      .then((res) => {
        setIsBusy(false);
        setSettingInfo(res);
      })
      .catch((err) => {
        setIsBusy(false);
        message.error(err);
      });
  };
  const onChange = (key) => { };
  const items = [
    {
      key: "1",
      label: "General Setup",
      children: (
        <GeneralTab
          activityList={activityList}
          registrationID={registrationID}
          setRegistrationID={setRegistrationID}
          settingInfo={settingInfo}
        />
      ),
    },
    {
      key: "2",
      label: "Home Page",
      children: (
        <HomeTab
          currentBackgroundUrl={currentBackgroundUrl}
          setCurrentBackgroundUrl={setCurrentBackgroundUrl}
          currentLogoUrl={currentLogoUrl}
          setCurrentLogoUrl={setCurrentLogoUrl}
          currentInfoUrl={currentInfoUrl}
          setCurrentInfoUrl={setCurrentInfoUrl}
          registrationID={registrationID}
          setRegistrationID={setRegistrationID}
          settingInfo={settingInfo}
        />
      ),
    },
    {
      key: "3",
      label: "Registration",
      children: (
        <RegistrationTab
          registrationID={registrationID}
          setRegistrationID={setRegistrationID}
          settingInfo={settingInfo}
        />
      ),
    },
    {
      key: "4",
      label: "Extra Programs",
      children: (
        <ExtraTab
          groupList={groupList}
          setGroupList={setGroupList}
          registrationID={registrationID}
          setRegistrationID={setRegistrationID}
          settingInfo={settingInfo}
        />
      ),
    },
    {
      key: "5",
      label: "Waivers",
      children: (
        <WaiverTab
          registrationID={registrationID}
          setRegistrationID={setRegistrationID}
          settingInfo={settingInfo}
        />
      ),
    },
  ];
  return (
    <CommonDivWidget>
      <div style={{ minHeight: "80vh" }}>
        <Spin spinning={isBusy}>
          <Tabs
            defaultActiveKey="1"
            items={items}
            onChange={onChange}
            className="home-setting"
          />
        </Spin>
      </div>
    </CommonDivWidget>
  );
}

export default HomeSettingPage;
