import { axiosGet, axiosPost, axiosPostResult } from "./ajaxServices";
import {
  urlRegistrationList,
  urlGetParticipant,
  urlRegistrationDetailByGroup,
  urlUpdateRegInfo,
  urlDeleteRegInfo,
  urlAvailableParticipant,
  urlGetParticipantByLabel,
  urlGetStandingList,
  urlRegistrationGroup,
  urlUpdateCampRegInfo,
  urlGetActivityList,
  urlSaveRegistrationGeneral,
  urlSaveHomePageInfo,
  urlSaveRegistrationGroup,
  urlChangeRegistrationStatus,
  urlGetRegistrationSettings,
  urlRegistrationTransaction,
  urlRegistrationTransactionByGroup,
  urlSaveRegistrationMain,
  urlSaveRegistrationWaiver
} from "./CONSTANTS";

export const apiGetRegistrationList = (params) => {
  const url = urlRegistrationList;
  return new Promise((resolve, reject) => {
    axiosPost(url, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiGetRegistrationDetail = (params) => {
  const url = urlGetParticipant;
  return new Promise((resolve, reject) => {
    axiosPost(url, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiGetRegistrationDetailByLabel = (params) => {
  const url = urlGetParticipantByLabel;
  return new Promise((resolve, reject) => {
    axiosPost(url, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiGetAvailableParticipant = (params) => {
  const url = urlAvailableParticipant;
  return new Promise((resolve, reject) => {
    axiosPost(url, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiGetRegistrationDetailByGroup = (params) => {
  const url = urlRegistrationDetailByGroup;
  return new Promise((resolve, reject) => {
    axiosPost(url, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiGetRegistrationTransactionByGroup = (params) => {
  const url = urlRegistrationTransactionByGroup;
  return new Promise((resolve, reject) => {
    axiosPost(url, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiGetRegGroupData = (params) => {
  const url = urlRegistrationGroup;
  return new Promise((resolve, reject) => {
    axiosPost(url, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiGetRegistrationTransaction = (params) => {
  const url = urlRegistrationTransaction;
  return new Promise((resolve, reject) => {
    axiosPost(url, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiUpdateCampRegInfo = (params) => {
  const url = urlUpdateCampRegInfo;
  return new Promise((resolve, reject) => {
    axiosPost(url, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiUpdateRegInfo = (params) => {
  const url = urlUpdateRegInfo;
  return new Promise((resolve, reject) => {
    axiosPost(url, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiDeleteRegInfo = (params) => {
  const url = urlDeleteRegInfo;
  return new Promise((resolve, reject) => {
    axiosPost(url, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiGetStandingList = (params) => {
  const url = urlGetStandingList;
  return new Promise((resolve, reject) => {
    axiosPost(url, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiGetActivityList = (params) => {
  const url = urlGetActivityList;
  return new Promise((resolve, reject) => {
    axiosPostResult(url, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiSaveRegistrationGeneral = (params) => {
  const url = urlSaveRegistrationGeneral;
  return new Promise((resolve, reject) => {
    axiosPost(url, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiSaveHomePageInfo = (param) => {
  const url = urlSaveHomePageInfo;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("token", param.token);
  formData.append("announcement", param.announcement);
  formData.append("title", param.title);
  formData.append("registration_id", param.registrationID);
  if (param.home_background != "") {
    if (param.home_background instanceof File) {
      formData.append("home_background", param.home_background);
    }
  }
  if (param.home_logo != "") {
    if (param.home_logo instanceof File) {
      formData.append("home_logo", param.home_logo);
    }
  }
  if (param.home_info != "") {
    if (param.home_info instanceof File) {
      formData.append("home_info", param.home_info);
    }
  }
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiSaveRegistrationMain = (params) => {
  const url = urlSaveRegistrationMain;
  return new Promise((resolve, reject) => {
    axiosPost(url, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiSaveRegistrationWaiver = (params) => {
  const url = urlSaveRegistrationWaiver;
  return new Promise((resolve, reject) => {
    axiosPost(url, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiSaveGroupInfo = (params) => {
  const url = urlSaveRegistrationGroup;
  return new Promise((resolve, reject) => {
    axiosPost(url, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const apiChangeRegistrationStatus = (params) => {
  const url = urlChangeRegistrationStatus;
  return new Promise((resolve, reject) => {
    axiosPost(url, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiGetRegistrationSettings = (params) => {
  const url = urlGetRegistrationSettings;
  return new Promise((resolve, reject) => {
    axiosPost(url, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
